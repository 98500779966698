<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refListTable"
      :items="dataList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nenhum registro encontrado"
      :sort-desc.sync="isSortDirDesc"
      striped
      style="min-height: 350px"
    >

      <template #thead-top>
        <b-tr>
          <b-th colspan="12">
            {{ totalCount }} Operações abertas
          </b-th>
        </b-tr>
      </template>

      <template #cell(paridade)="data">
        <div class="text-nowrap">
          <b-avatar
            size="sm"
            :src="getIcon(data.item.moedaTrade)"
          />
          <span class="mr-1">
            {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
          </span>
        </div>
      </template>

      <template #cell(ordemPosition)="data">
        <div class="text-nowrap">
          {{ data.value }}
          <b-badge variant="light-warning">
            {{ data.item.alavancagem }}x
          </b-badge>
        </div>
      </template>

      <template #cell(ordemTipoCompra)="data">
        {{ data.value }}
        <small v-if="data.value=='Stop Limite'">
          ({{ data.item.valorEntradaStop }})
        </small>
      </template>

      <template #cell(preco)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          {{ tickers[data.item.moedaParidade].price }}
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(status)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          <div class="text-nowrap">
            <span
              v-if="data.item.ordemPosition=='Long'"
              :class="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.valorEntradaMedio)? 'text-success' : 'text-danger'"
            >
              {{ percentDiferenca(data.item.valorEntradaMedio, tickers[data.item.moedaParidade].price) }}%
              <br>
              <b-badge :variant="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.valorEntradaMedio)? 'light-success' : 'light-danger'">
                {{ toFixed(percentDiferenca(data.item.valorEntradaMedio, tickers[data.item.moedaParidade].price) * data.item.alavancagem, 2) }}%
              </b-badge>
            </span>
            <span
              v-if="data.item.ordemPosition=='Short'"
              :class="parseFloat(data.item.valorEntradaMedio) >= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
            >
              {{ percentDiferenca(tickers[data.item.moedaParidade].price, data.item.valorEntradaMedio) }}%
              <br>
              <b-badge :variant="parseFloat(data.item.valorEntradaMedio) >= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
                {{ toFixed(percentDiferenca(tickers[data.item.moedaParidade].price, data.item.valorEntradaMedio) * data.item.alavancagem, 2) }}%
              </b-badge>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(dataCriacao)="data">
        {{ formatDateTimeSemAnoBR(data.value) }}
      </template>

      <template #cell(dataEntrada)="data">
        {{ formatDateTimeSemAnoBR(data.value) }}
      </template>

      <template #cell(alvo1)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <template v-if="data.item.ordemPosition=='Long'">
            <b-badge variant="light-success">
              {{ getPercentDiff(data.item.valorEntradaMedio, data.value) }}%
            </b-badge>
          </template>
          <template v-else>
            <b-badge variant="light-success">
              {{ getPercentDiff(data.value, data.item.valorEntradaMedio) }}%
            </b-badge>
          </template>
          <feather-icon
            v-if="data.item.dataAlvo1!=null"
            class="text-success mr-1"
            icon="CheckIcon"
          />
        </template>
        <template v-else>
          -
        </template>

      </template>

      <template #cell(stopLoss)="data">
        <template v-if="data.value">
          {{ data.value }} <br>
          <b-badge
            v-if="data.item.ordemPosition=='Long'"
            variant="light-danger"
          >
            {{ getPercentDiff(data.item.valorEntradaMedio, data.value) }}%
          </b-badge>
          <b-badge
            v-else
            variant="light-danger"
          >
            {{ getPercentDiff(data.value, data.item.valorEntradaMedio) }}%
          </b-badge>
        </template>
        <template v-else>
          -
        </template>
      </template>

      <template #cell(loteMedio)="data">
        <div class="text-nowrap">
          <template v-if="data.value!=''">
            {{ data.value }}%
            <span
              v-if="data.item.parciais.length > 0"
              class="card-text font-small-3"
            >
              ({{ data.item.parciais.length }})
            </span>
          </template>
          <template v-else />
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(acao)="data">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            :disabled="!data.item.ordemEntradaAbertaQtde==0 || lockFunctionTime(data.item.dataCriacao)"
            @click.stop="confirmarStopGain(data.item.id)"
          >
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">Stop gain</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-entrada-parcial
            :disabled="!data.item.ordemEntradaAbertaQtde==0 || lockFunctionTime(data.item.dataCriacao)"
            @click="dataModal = data.item"
          >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ml-50">Entrada parcial</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-saida-parcial
            :disabled="!data.item.ordemEntradaAbertaQtde==0 || lockFunctionTime(data.item.dataCriacao)"
            @click="dataModal = data.item"
          >
            <feather-icon icon="PlusIcon" />
            <span class="align-middle ml-50">Saída parcial</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-alterar-alvo
            :disabled="!(data.item.ordemEntradaAbertaQtde==0 && data.item.posicaoAbertaQtde>0) || lockFunctionTime(data.item.dataCriacao)"
            @click="dataModal = data.item"
          >
            <feather-icon :icon="data.item.alvo1 ? 'EditIcon' : 'PlusIcon'" />
            <span class="align-middle ml-50">
              Alvo
            </span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-alterar-stop
            :disabled="!(data.item.ordemEntradaAbertaQtde==0 && data.item.posicaoAbertaQtde>0) || lockFunctionTime(data.item.dataCriacao)"
            @click="dataModal = data.item"
          >
            <feather-icon :icon="data.item.stopLoss ? 'EditIcon' : 'PlusIcon'" />
            Stoploss
          </b-dropdown-item>

          <b-dropdown-item
            :disabled="lockFunctionTime(data.item.dataCriacao)"
            @click.stop="confirmarEncerrarPosicao(data.item.id)"
          >
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">Encerrar</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.modal-assinantes
            @click="loadModalSinalAssinantes(data.item)"
          >
            <feather-icon icon="UsersIcon" />
            <span class="align-middle ml-50">Assinantes</span>
          </b-dropdown-item>

        </b-dropdown>

      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ paginationMeta.from }} a {{ paginationMeta.to }} de {{ paginationMeta.of }} registros</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      v-if="dataModal"
      id="modal-assinantes"
      ref="modal-assinantes"
      size="xl"
      centered
      :title="`Sinal #${dataModal.id} - Assinantes`"
      ok-only
      ok-title="Fechar"
      hide-header-close="true"
      @ok="dataModal = null"
    >
      <b-tabs>
        <b-tab title="Posição">
          <b-table
            ref="refListTableSinalPosicao"
            :items="dataListSinalPosicao"
            responsive
            :fields="tableColumnsSinalPosicao"
            primary-key="id"
            :sort-by.sync="sortBySinalPosicao"
            show-empty
            empty-text="Nenhum registro encontrado"
            :sort-desc.sync="isSortDirDescSinalPosicao"
            striped
          >

            <template #cell(stopLoss)="data">
              <template v-if="data.value">
                {{ data.value }} <br>
                <b-badge
                  v-if="data.item.ordemPosition=='Long'"
                  variant="light-danger"
                >
                  {{ getPercentDiff(data.item.precoCompra, data.item.stopLoss) }}%
                </b-badge>
                <b-badge
                  v-else
                  variant="light-danger"
                >
                  {{ getPercentDiff(data.item.stopLoss, data.item.precoCompra) }}%
                </b-badge>
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template #cell(alvo)="data">
              <template v-if="data.value">
                {{ toFixed(data.value, 6) }} <br>
                <b-badge variant="light-success">
                  <template v-if="data.item.ordemPosition=='Long'">
                    {{ getPercentDiff(data.item.precoCompra, data.item.alvo) }}%
                  </template>
                  <template v-else>
                    {{ getPercentDiff(data.item.alvo, data.item.precoCompra) }}%
                  </template>
                </b-badge>
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template #cell(statusWs)="data">
              <template v-if="tickers[data.item.moedaParidade]!=null && data.item.status == 'Posição aberta'">
                <div class="text-nowrap">
                  <span
                    v-if="data.item.ordemPosition=='Long'"
                    :class="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.precoCompra)? 'text-success' : 'text-danger'"
                  >
                    {{ toFixed(((parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra)) * data.item.quantidade), 2) }}
                    <small>USDT</small>
                    <br>
                    <b-badge :variant="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.precoCompra)? 'light-success' : 'light-danger'">

                      ({{ toFixed(((( parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra) ) * data.item.quantidade)
                        / (data.item.quantidade * parseFloat(data.item.precoCompra) * (1/data.item.alavancagem))) * 100, 1) }}%)

                    </b-badge>

                  </span>
                  <span
                    v-if="data.item.ordemPosition=='Short'"
                    :class="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
                  >
                    {{ toFixed( -1 * ((parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra)) * data.item.quantidade), 2) }}
                    <small>USDT</small>
                    <br>
                    <b-badge :variant="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
                      ({{ toFixed(-1 * ((( parseFloat(tickers[data.item.moedaParidade].price) - parseFloat(data.item.precoCompra) ) * data.item.quantidade)
                        / (data.item.quantidade * parseFloat(data.item.precoCompra) * (1/data.item.alavancagem))) * 100, 1) }}%)
                    </b-badge>
                  </span>

                </div>
              </template>
              <template v-else-if="data.item.status == 'Posição aberta'">
                <div class="text-center">
                  <b-spinner small />
                </div>
              </template>
            </template>

            <template #cell(precoWs)="data">
              <template v-if="tickers[data.item.moedaParidade]!=null && data.item.status == 'Posição aberta'">
                <span
                  v-if="data.item.ordemPosition=='Long'"
                  :class="parseFloat(data.item.precoCompra) <= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
                >
                  {{ tickers[data.item.moedaParidade].price }}
                  <br>
                  <b-badge :variant="parseFloat(data.item.precoCompra) <= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
                    {{ getPercentDiff(data.item.precoCompra, tickers[data.item.moedaParidade].price ) }}%
                  </b-badge>
                </span>
                <span
                  v-else
                  :class="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'text-success' : 'text-danger'"
                >
                  {{ tickers[data.item.moedaParidade].price }}
                  <br>
                  <b-badge :variant="parseFloat(data.item.precoCompra) >= parseFloat(tickers[data.item.moedaParidade].price)? 'light-success' : 'light-danger'">
                    {{ getPercentDiff(data.item.precoCompra, tickers[data.item.moedaParidade].price ) }}%
                  </b-badge>
                </span>
              </template>
              <template v-else-if="data.item.status == 'Posição aberta'">
                <div class="text-center">
                  <b-spinner small />
                </div>
              </template>
            </template>

            <template #cell(dataExecucao)="data">
              {{ formatDateTimeSemAnoBR(data.value) }}
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaSinalPosicao.from }} a {{ paginationMetaSinalPosicao.to }} de {{ paginationMetaSinalPosicao.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageSinalPosicao"
                  :total-rows="totalCountSinalPosicao"
                  :per-page="perPageSinalPosicao"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-tab>
        <b-tab title="Saída parcial">
          <b-table
            ref="refListTableSinalSaidaParcial"
            :items="dataListSinalSaidaParcial"
            responsive
            :fields="tableColumnsSinalSaidaParcial"
            primary-key="id"
            :sort-by.sync="sortBySinalSaidaParcial"
            show-empty
            empty-text="Nenhum registro encontrado"
            :sort-desc.sync="isSortDirDescSinalSaidaParcial"
            striped
          >
            <template #cell(dataExecucao)="data">
              {{ formatDateTimeSemAnoBR(data.value) }}
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaSinalSaidaParcial.from }} a {{ paginationMetaSinalSaidaParcial.to }} de {{ paginationMetaSinalSaidaParcial.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageSinalSaidaParcial"
                  :total-rows="totalCountSinalSaidaParcial"
                  :per-page="perPageSinalSaidaParcial"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-tab>
        <b-tab title="Entrada parcial">
          <b-table
            ref="refListTableSinalEntradaParcial"
            :items="dataListSinalEntradaParcial"
            responsive
            :fields="tableColumnsSinalEntradaParcial"
            primary-key="id"
            :sort-by.sync="sortBySinalEntradaParcial"
            show-empty
            empty-text="Nenhum registro encontrado"
            :sort-desc.sync="isSortDirDescSinalEntradaParcial"
            striped
          >
            <template #cell(dataExecucao)="data">
              {{ formatDateTimeSemAnoBR(data.value) }}
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaSinalEntradaParcial.from }} a {{ paginationMetaSinalEntradaParcial.to }} de {{ paginationMetaSinalEntradaParcial.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageSinalEntradaParcial"
                  :total-rows="totalCountSinalEntradaParcial"
                  :per-page="perPageSinalEntradaParcial"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-tab>
        <b-tab title="Entrada">
          <b-table
            ref="refListTableSinalEntrada"
            :items="dataListSinalEntrada"
            responsive
            :fields="tableColumnsSinalEntrada"
            primary-key="id"
            :sort-by.sync="sortBySinalEntrada"
            show-empty
            empty-text="Nenhum registro encontrado"
            :sort-desc.sync="isSortDirDescSinalEntrada"
            striped
          >
            <template #cell(dataExecucao)="data">
              {{ formatDateTimeSemAnoBR(data.value) }}
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaSinalEntrada.from }} a {{ paginationMetaSinalEntrada.to }} de {{ paginationMetaSinalEntrada.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageSinalEntrada"
                  :total-rows="totalCountSinalEntrada"
                  :per-page="perPageSinalEntrada"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      id="modal-alterar-stop"
      ref="modal-alterar-stop"
      cancel-variant="outline-secondary"
      :ok-title="dataModal && dataModal.stopLoss ? 'Alterar' : 'Adicionar'"
      cancel-title="Sair"
      centered
      :title="dataModal && dataModal.stopLoss ? 'Alterar stop' : 'Adicionar stop'"
      @show="resetModalStop"
      @hidden="resetModalStop"
      @ok="gerenciarStopHandler"
    >
      <b-form
        v-if="dataModal"
        ref="formstopnovo"
        @submit.stop.prevent="gerenciarStop"
      >
        <b-form-group>
          <label for="preco-atual">
            Preço atual
            <b-badge
              v-if="dataModal.ordemPosition=='Long'"
              :variant="parseFloat(tickers[dataModal.moedaParidade].price) >= parseFloat(dataModal.valorEntradaMedio)? 'light-success' : 'light-danger'"
            >
              {{ percentDiferenca(dataModal.valorEntradaMedio, tickers[dataModal.moedaParidade].price) }}%
            </b-badge>
            <b-badge
              v-if="dataModal.ordemPosition=='Short'"
              :variant="parseFloat(dataModal.valorEntradaMedio) >= parseFloat(tickers[dataModal.moedaParidade].price)? 'light-success' : 'light-danger'"
            >
              {{ percentDiferenca(tickers[dataModal.moedaParidade].price, dataModal.valorEntradaMedio) }}%
            </b-badge>
          </label>
          <b-form-input
            id="preco-atual"
            type="text"
            disabled
            :value="tickers[dataModal.moedaParidade].price"
          />
        </b-form-group>
        <b-form-group v-if="dataModal.stopLoss">
          <label for="stop-atual">
            Stop atual
            <template v-if="dataModal.ordemPosition=='Long'">
              <b-badge variant="light-danger">
                {{ getPercentDiff(dataModal.valorEntradaMedio, dataModal.stopLoss) }}%
              </b-badge>
            </template>
            <template v-else>
              <b-badge variant="light-danger">
                {{ getPercentDiff(dataModal.stopLoss, dataModal.valorEntradaMedio) }}%
              </b-badge>
            </template>
          </label>
          <b-form-input
            id="stop-atual"
            type="text"
            disabled
            :value="dataModal.stopLoss"
          />
        </b-form-group>
        <b-form-group
          :state="novoStopState"
          invalid-feedback="Campo Stop novo obrigatório"
        >
          <label for="stop-novo">
            Stop novo
            <template v-if="novoStop">
              <template v-if="dataModal.ordemPosition=='Long'">
                <b-badge variant="light-danger">
                  {{ getPercentDiff(dataModal.valorEntradaMedio, novoStop) }}%
                </b-badge>
              </template>
              <template v-else>
                <b-badge variant="light-danger">
                  {{ getPercentDiff(novoStop, dataModal.valorEntradaMedio) }}%
                </b-badge>
              </template>
            </template>
          </label>
          <b-form-input
            id="alvo-novo"
            v-model="novoStop"
            :state="novoStopState"
            required
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-alterar-alvo"
      ref="modal-alterar-alvo"
      cancel-variant="outline-secondary"
      :ok-title="dataModal && dataModal.alvo1 ? 'Alterar' : 'Adicionar'"
      cancel-title="Sair"
      centered
      :title="dataModal && dataModal.alvo1 ? 'Alterar alvo' : 'Adicionar alvo'"
      @show="resetModalAlvo"
      @hidden="resetModalAlvo"
      @ok="gerenciarAlvoHandler"
    >
      <b-form
        v-if="dataModal"
        ref="formalvonovo"
        @submit.stop.prevent="gerenciarAlvo"
      >
        <b-form-group>
          <label for="preco-atual">
            Preço atual
            <b-badge
              v-if="dataModal.ordemPosition=='Long'"
              :variant="parseFloat(tickers[dataModal.moedaParidade].price) >= parseFloat(dataModal.valorEntradaMedio)? 'light-success' : 'light-danger'"
            >
              {{ percentDiferenca(dataModal.valorEntradaMedio, tickers[dataModal.moedaParidade].price) }}%
            </b-badge>
            <b-badge
              v-if="dataModal.ordemPosition=='Short'"
              :variant="parseFloat(dataModal.valorEntradaMedio) >= parseFloat(tickers[dataModal.moedaParidade].price)? 'light-success' : 'light-danger'"
            >
              {{ percentDiferenca(tickers[dataModal.moedaParidade].price, dataModal.valorEntradaMedio) }}%
            </b-badge>
          </label>
          <b-form-input
            id="preco-atual"
            type="text"
            disabled
            :value="tickers[dataModal.moedaParidade].price"
          />
        </b-form-group>
        <b-form-group v-if="dataModal.alvo1">
          <label for="alvo-atual">
            Alvo atual
            <template v-if="dataModal.ordemPosition=='Long'">
              <b-badge variant="light-success">
                {{ getPercentDiff(dataModal.valorEntradaMedio, dataModal.alvo1) }}%
              </b-badge>
            </template>
            <template v-else>
              <b-badge variant="light-success">
                {{ getPercentDiff(dataModal.alvo1, dataModal.valorEntradaMedio) }}%
              </b-badge>
            </template>
          </label>
          <b-form-input
            id="alvo-atual"
            type="text"
            disabled
            :value="dataModal.alvo1"
          />
        </b-form-group>
        <b-form-group
          :state="novoAlvoState"
          invalid-feedback="Campo Alvo novo obrigatório"
        >
          <label for="alvo-novo">
            Alvo novo
            <template v-if="novoAlvo">
              <template v-if="dataModal.ordemPosition=='Long'">
                <b-badge variant="light-success">
                  {{ getPercentDiff(dataModal.valorEntradaMedio, novoAlvo) }}%
                </b-badge>
              </template>
              <template v-else>
                <b-badge variant="light-success">
                  {{ getPercentDiff(novoAlvo, dataModal.valorEntradaMedio) }}%
                </b-badge>
              </template>
            </template>
          </label>
          <b-form-input
            id="alvo-novo"
            v-model="novoAlvo"
            :state="novoAlvoState"
            required
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-entrada-parcial"
      ref="modal-entrada-parcial"
      cancel-variant="outline-secondary"
      ok-title="Adicionar"
      cancel-title="Sair"
      centered
      title="Nova entrada"
      @show="resetModalEntradaParcial"
      @hidden="resetModalEntradaParcial"
      @ok="gerenciarEntradaParcialHandler"
    >
      <validation-observer ref="formentradaparcial">
        <b-form
          v-if="dataModal"
        >

          <b-form-group>
            <label for="preco-entrada">
              Preço entrada
            </label>
            <b-form-input
              id="preco-entrada"
              type="text"
              disabled
              :value="dataModal.valorEntradaMedio"
            />
          </b-form-group>

          <b-form-group>
            <label for="preco-atual">
              Preço atual
              <b-badge
                v-if="dataModal.ordemPosition=='Long'"
                :variant="parseFloat(tickers[dataModal.moedaParidade].price) >= parseFloat(dataModal.valorEntradaMedio)? 'light-success' : 'light-danger'"
              >
                {{ percentDiferenca(dataModal.valorEntradaMedio, tickers[dataModal.moedaParidade].price) }}%
              </b-badge>
              <b-badge
                v-if="dataModal.ordemPosition=='Short'"
                :variant="parseFloat(dataModal.valorEntradaMedio) >= parseFloat(tickers[dataModal.moedaParidade].price)? 'light-success' : 'light-danger'"
              >
                {{ percentDiferenca(tickers[dataModal.moedaParidade].price, dataModal.valorEntradaMedio) }}%
              </b-badge>
            </label>
            <b-form-input
              id="preco-atual"
              type="text"
              disabled
              :value="tickers[dataModal.moedaParidade].price"
            />
          </b-form-group>
          <b-form-group
            :state="novoEntradaParcialState"
            invalid-feedback="Campo Percentual obrigatório"
          >
            <label for="entrada-parcial-novo">
              Percentual do saldo total
            </label>
            <validation-provider
              #default="{ errors }"
              name="Porcentagem"
              :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: 0.03, max_value: 50 }"
            >
              <b-form-input
                id="entrada-parcial-novo"
                v-model="novoEntradaParcial"
                :state="novoEntradaParcialState"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-saida-parcial"
      ref="modal-saida-parcial"
      cancel-variant="outline-secondary"
      ok-title="Adicionar"
      cancel-title="Sair"
      centered
      title="Nova Saída"
      @show="resetModalSaidaParcial"
      @hidden="resetModalSaidaParcial"
      @ok="gerenciarSaidaParcialHandler"
    >
      <validation-observer ref="formsaidaparcial">
        <b-form
          v-if="dataModal"
        >

          <b-form-group>
            <label for="preco-entrada">
              Preço entrada
            </label>
            <b-form-input
              id="preco-entrada"
              type="text"
              disabled
              :value="dataModal.valorEntradaMedio"
            />
          </b-form-group>

          <b-form-group>
            <label for="preco-atual">
              Preço saída
              <b-badge
                v-if="dataModal.ordemPosition=='Long'"
                :variant="parseFloat(tickers[dataModal.moedaParidade].price) >= parseFloat(dataModal.valorEntradaMedio)? 'light-success' : 'light-danger'"
              >
                {{ percentDiferenca(dataModal.valorEntradaMedio, tickers[dataModal.moedaParidade].price) }}%
              </b-badge>
              <b-badge
                v-if="dataModal.ordemPosition=='Short'"
                :variant="parseFloat(dataModal.valorEntradaMedio) >= parseFloat(tickers[dataModal.moedaParidade].price)? 'light-success' : 'light-danger'"
              >
                {{ percentDiferenca(tickers[dataModal.moedaParidade].price, dataModal.valorEntradaMedio) }}%
              </b-badge>
            </label>
            <b-form-input
              id="preco-atual"
              type="text"
              disabled
              :value="tickers[dataModal.moedaParidade].price"
            />
          </b-form-group>
          <b-form-group
            :state="novoSaidaParcialState"
            invalid-feedback="Campo Percentual obrigatório"
          >
            <label for="saida-parcial-novo">
              Percentual da quantidade atual
            </label>
            <validation-provider
              #default="{ errors }"
              name="Porcentagem"
              :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: 10, max_value: 75 }"
            >
              <b-form-input
                id="saida-parcial-novo"
                v-model="novoSaidaParcial"
                :state="novoSaidaParcialState"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BTable, BTabs, BTab, BAvatar, BBadge, BPagination, BRow, BCol, BTr, BTh, BSpinner, BDropdown, BDropdownItem, BModal, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca } from '@/utils/filter'
import operacaoAbertaStoreModule from './operacaoAbertaStoreModule'

const AGENTE_OPERACAO_ABERTA_FUT_STORE_MODULE_NAME = 'agente-operacao-aberta-fut'

export default {

  components: {
    BCard,
    BTable,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BTr,
    BTh,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      required,
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'paridade', label: 'Par' },
        { key: 'ordemPosition', label: 'Posição' },
        { key: 'ordemTipoCompra', label: 'Tipo entrada' },
        { key: 'valorEntradaMedio', label: 'Entrada' },
        { key: 'preco', label: 'Preço' },
        { key: 'status', label: 'Status(ROE)' },
        { key: 'alvo1', label: 'Alvo' },
        { key: 'stopLoss', label: 'Stop' },
        { key: 'ordemEntradaAbertaQtde', label: 'Entradas pendente' },
        { key: 'posicaoAbertaQtde', label: 'Posições abertas' },
        { key: 'loteMedio', label: 'Investir' },
        { key: 'dataCriacao', label: 'Data envio' },
        { key: 'dataEntrada', label: 'Data entrada' },
        { key: 'agente', label: 'Agente' },
        { key: 'acao', label: 'Ações' },
      ],
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
      dataModal: null,
      novoAlvo: null,
      novoAlvoState: null,
      novoStop: null,
      novoStopState: null,
      novoEntradaParcial: null,
      novoEntradaParcialState: null,
      novoSaidaParcial: null,
      novoSaidaParcialState: null,

      tableColumnsSinalEntrada: [
        { key: 'moedaParidade', label: 'Par' },
        { key: 'emailUsuario', label: 'Assinante' },
        { key: 'ordemTipo', label: 'Tipo entrada' },
        { key: 'preco', label: 'Preço entrada' },
        { key: 'quantidade', label: 'QTD' },
        { key: 'status', label: 'Status' },
        { key: 'dataExecucao', label: 'Data execução' },
      ],

      dataListSinalEntrada: [],
      perPageSinalEntrada: 10,
      totalCountSinalEntrada: 0,
      currentPageSinalEntrada: 1,
      sortBySinalEntrada: 'id,desc',
      isSortDirDescSinalEntrada: true,

      tableColumnsSinalEntradaParcial: [
        { key: 'moedaParidade', label: 'Par' },
        { key: 'emailUsuario', label: 'Assinante' },
        { key: 'ordemTipo', label: 'Tipo entrada' },
        { key: 'preco', label: 'Preço entrada' },
        { key: 'quantidade', label: 'QTD' },
        { key: 'status', label: 'Status' },
        { key: 'dataExecucao', label: 'Data execução' },
      ],

      dataListSinalEntradaParcial: [],
      perPageSinalEntradaParcial: 10,
      totalCountSinalEntradaParcial: 0,
      currentPageSinalEntradaParcial: 1,
      sortBySinalEntradaParcial: 'id,desc',
      isSortDirDescSinalEntradaParcial: true,

      tableColumnsSinalSaidaParcial: [
        { key: 'moedaParidade', label: 'Par' },
        { key: 'emailUsuario', label: 'Assinante' },
        { key: 'ordemTipo', label: 'Tipo saída' },
        { key: 'preco', label: 'Preço entrada' },
        { key: 'quantidade', label: 'QTD' },
        { key: 'status', label: 'Status' },
        { key: 'dataExecucao', label: 'Data execução' },
      ],

      dataListSinalSaidaParcial: [],
      perPageSinalSaidaParcial: 10,
      totalCountSinalSaidaParcial: 0,
      currentPageSinalSaidaParcial: 1,
      sortBySinalSaidaParcial: 'id,desc',
      isSortDirDescSinalSaidaParcial: true,

      tableColumnsSinalPosicao: [
        { key: 'moedaParidade', label: 'Par' },
        { key: 'emailUsuario', label: 'Assinante' },
        { key: 'quantidade', label: 'QTD' },
        { key: 'precoCompra', label: 'Entrada' },
        { key: 'stopLoss', label: 'Stop' },
        { key: 'precoWs', label: 'Preço' },
        { key: 'alvo', label: 'Alvo' },
        { key: 'statusWs', label: 'PNL(ROE%)' },
        { key: 'status', label: 'Status' },
      ],
      dataListSinalPosicao: [],
      perPageSinalPosicao: 10,
      totalCountSinalPosicao: 0,
      currentPageSinalPosicao: 1,
      sortBySinalPosicao: 'id,desc',
      isSortDirDescSinalPosicao: true,
    }
  },

  computed: {

    tickers() {
      return this.$store.state.binanceFut.tickers
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

    paginationMetaSinalEntrada() {
      const localItemsCount = this.$refs.refListTableSinalEntrada ? this.$refs.refListTableSinalEntrada.localItems.length : 0
      return {
        from: this.perPageSinalEntrada * (this.currentPageSinalEntrada - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageSinalEntrada * (this.currentPageSinalEntrada - 1) + localItemsCount,
        of: this.totalCountSinalEntrada,
      }
    },

    paginationMetaSinalSaidaParcial() {
      const localItemsCount = this.$refs.refListTableSinalSaidaParcial ? this.$refs.refListTableSinalSaidaParcial.localItems.length : 0
      return {
        from: this.perPageSinalSaidaParcial * (this.currentPageSinalSaidaParcial - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageSinalSaidaParcial * (this.currentPageSinalSaidaParcial - 1) + localItemsCount,
        of: this.totalCountSinalSaidaParcial,
      }
    },

    paginationMetaSinalEntradaParcial() {
      const localItemsCount = this.$refs.refListTableSinalEntradaParcial ? this.$refs.refListTableSinalEntradaParcial.localItems.length : 0
      return {
        from: this.perPageSinalEntradaParcial * (this.currentPageSinalEntradaParcial - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageSinalEntradaParcial * (this.currentPageSinalEntradaParcial - 1) + localItemsCount,
        of: this.totalCountSinalEntradaParcial,
      }
    },

    paginationMetaSinalPosicao() {
      const localItemsCount = this.$refs.refListTableSinalPosicao ? this.$refs.refListTableSinalPosicao.localItems.length : 0
      return {
        from: this.perPageSinalPosicao * (this.currentPageSinalPosicao - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageSinalPosicao * (this.currentPageSinalPosicao - 1) + localItemsCount,
        of: this.totalCountSinalPosicao,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
    currentPageSinalEntrada() {
      this.fetchSinalEntradaList(this.dataModal)
      this.$refs.refListTableSinalEntrada.refresh()
    },
    currentPageSinalEntradaParcial() {
      this.fetchSinalEntradaParcialList(this.dataModal)
      this.$refs.refListTableSinalEntradaParcial.refresh()
    },
    currentPageSinalSaidaParcial() {
      this.fetchSinalSaidaParcialList(this.dataModal)
      this.$refs.refListTableSinalSaidaParcial.refresh()
    },
    currentPageSinalPosicao() {
      this.fetchSinalPosicaoList(this.dataModal)
      this.$refs.refListTableSinalPosicao.refresh()
    },
  },

  created() {
    if (!store.hasModule(AGENTE_OPERACAO_ABERTA_FUT_STORE_MODULE_NAME)) store.registerModule(AGENTE_OPERACAO_ABERTA_FUT_STORE_MODULE_NAME, operacaoAbertaStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    clearInterval(this.polling)
    if (store.hasModule(AGENTE_OPERACAO_ABERTA_FUT_STORE_MODULE_NAME)) store.unregisterModule(AGENTE_OPERACAO_ABERTA_FUT_STORE_MODULE_NAME)
  },

  methods: {

    loadModalSinalAssinantes(item) {
      this.fetchSinalEntradaList(item)
      this.fetchSinalEntradaParcialList(item)
      this.fetchSinalSaidaParcialList(item)
      this.fetchSinalPosicaoList(item)
      this.dataModal = item
    },

    fetchSinalPosicaoList(item) {
      store
        .dispatch('agente-operacao-aberta-fut/getSinalAgentePosicao', {
          size: this.perPageSinalPosicao,
          page: this.currentPageSinalPosicao - 1,
          sortBy: this.sortBySinalPosicao,
          sortDesc: this.isSortDirDescSinalPosicao,
          sinal: item.id,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataListSinalPosicao = content
          this.totalCountSinalPosicao = totalElements
        })
    },

    fetchSinalEntradaParcialList(item) {
      store
        .dispatch('agente-operacao-aberta-fut/getSinalAgenteEntradaParcial', {
          size: this.perPageSinalEntradaParcial,
          page: this.currentPageSinalEntradaParcial - 1,
          sortBy: this.sortBySinalEntradaParcial,
          sortDesc: this.isSortDirDescSinalEntradaParcial,
          sinal: item.id,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataListSinalEntradaParcial = content
          this.totalCountSinalEntradaParcial = totalElements
        })
    },

    fetchSinalSaidaParcialList(item) {
      store
        .dispatch('agente-operacao-aberta-fut/getSinalAgenteSaidaParcial', {
          size: this.perPageSinalSaidaParcial,
          page: this.currentPageSinalSaidaParcial - 1,
          sortBy: this.sortBySinalSaidaParcial,
          sortDesc: this.isSortDirDescSinalSaidaParcial,
          sinal: item.id,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataListSinalSaidaParcial = content
          this.totalCountSinalSaidaParcial = totalElements
        })
    },

    fetchSinalEntradaList(item) {
      store
        .dispatch('agente-operacao-aberta-fut/getSinalAgenteEntrada', {
          size: this.perPageSinalEntrada,
          page: this.currentPageSinalEntrada - 1,
          sortBy: this.sortBySinalEntrada,
          sortDesc: this.isSortDirDescSinalEntrada,
          sinal: item.id,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataListSinalEntrada = content
          this.totalCountSinalEntrada = totalElements
        })
    },

    lockFunctionTime(dataCriacao) {
      const dataUnlock = new Date(dataCriacao)
      dataUnlock.setMinutes(dataUnlock.getMinutes() + 1)
      return new Date() < dataUnlock
    },

    // entrada parcial
    resetModalEntradaParcial() {
      this.novoEntradaParcial = null
      this.novoEntradaParcialState = null
    },

    gerenciarEntradaParcialHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarEntradaParcialSubmit()
    },

    gerenciarEntradaParcialSubmit() {
      // Exit when the form isn't valid
      this.$refs.formentradaparcial.validate().then(success => {
        if (success) {
          this.novoEntradaParcialState = success
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['modal-entrada-parcial'].toggle('#toggle-btn')
          })
          this.entradaParcial()
        } else {
          this.novoEntradaParcialState = success
        }
      })
    },

    entradaParcial() {
      this.$store.dispatch('agente-operacao-aberta-fut/entradaParcial', { idSinal: this.dataModal.id, porcentagem: this.novoEntradaParcial })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Entrada parcial efetuada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // saida parcial
    resetModalSaidaParcial() {
      this.novoSaidaParcial = null
      this.novoSaidaParcialState = null
    },

    gerenciarSaidaParcialHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarSaidaParcialSubmit()
    },

    gerenciarSaidaParcialSubmit() {
      // Exit when the form isn't valid
      this.$refs.formsaidaparcial.validate().then(success => {
        if (success) {
          this.novoSaidaParcialState = success
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['modal-saida-parcial'].toggle('#toggle-btn')
          })
          this.saidaParcial()
        } else {
          this.novoSaidaParcialState = success
        }
      })
    },

    saidaParcial() {
      this.$store.dispatch('agente-operacao-aberta-fut/saidaParcial', { idSinal: this.dataModal.id, porcentagem: this.novoSaidaParcial })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Saída parcial efetuada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // stop novo

    checkFormValidityStop() {
      const valid = this.$refs.formstopnovo.checkValidity()
      this.novoStopState = valid
      return valid
    },

    resetModalStop() {
      this.novoStop = null
      this.novoStopState = null
    },

    gerenciarStopHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarStopSubmit()
    },

    gerenciarStopSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidityStop()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-alterar-stop'].toggle('#toggle-btn')
      })

      this.gerenciarStop()
    },

    gerenciarStop() {
      this.$store.dispatch('agente-operacao-aberta-fut/gerenciarStop', { id: this.dataModal.id, novoStop: this.novoStop })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Stop alterado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // alvo novo

    checkFormValidityAlvo() {
      const valid = this.$refs.formalvonovo.checkValidity()
      this.novoAlvoState = valid
      return valid
    },

    resetModalAlvo() {
      this.novoAlvo = null
      this.novoAlvoState = null
    },

    gerenciarAlvoHandler(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.gerenciarAlvoSubmit()
    },

    gerenciarAlvoSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidityAlvo()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-alterar-alvo'].toggle('#toggle-btn')
      })

      this.gerenciarAlvo()
    },

    gerenciarAlvo() {
      this.$store.dispatch('agente-operacao-aberta-fut/gerenciarAlvo', { id: this.dataModal.id, novoAlvo: this.novoAlvo })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Alvo alterado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    // stop gain
    confirmarStopGain(id) {
      this.$swal({
        title: 'Tem certeza que deseja acionar o stop gain?',
        text: 'Todos os seguidores desta operação teram suas posições encerradas a preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.stopGain(id)
        }
      })
    },

    confirmarEncerrarPosicao(id) {
      this.$swal({
        title: 'Tem certeza que deseja encerrar a posição?',
        text: 'Todos os seguidores desta operação teram suas posições encerradas a preço de mercado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.encerrarPosicao(id)
        }
      })
    },

    encerrarPosicao(id) {
      this.$store.dispatch('agente-operacao-aberta-fut/encerrarPosicao', { id })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Encerrar posição acionado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    stopGain(id) {
      this.$store.dispatch('agente-operacao-aberta-fut/stopGain', { id })
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Stop gain acionado com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data[0].erro,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },

    fetchList() {
      store
        .dispatch('agente-operacao-aberta-fut/getSinais', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          statusSinal: 0,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    pollfetchList() {
      this.fetchList()
      const self = this
      this.polling = setInterval(() => {
        self.fetchList()
      }, 5000)
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
