import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/fut/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getSinalAgenteEntrada(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/sinal/agente/ordem-entrada', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getSinalAgenteEntradaParcial(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/sinal/agente/ordem-entrada-parcial', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getSinalAgenteSaidaParcial(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/sinal/agente/ordem-saida-parcial', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getSinalAgentePosicao(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/sinal/agente/posicao', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    getSinais(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/sinal/agente', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    encerrarPosicao(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/encerrar-posicao', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    stopGain(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/stop-gain', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    gerenciarAlvo(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/alterar-alvo', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    entradaParcial(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/entrada-parcial', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    saidaParcial(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/saida-parcial', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    gerenciarStop(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('fut/sinal/alterar-stop', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
